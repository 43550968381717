const goToSearchPage = (router, { need, equipment, zipcode }) => {
  router.push({
    pathname: '/trouver-mon-chauffagiste',
    query: {
      need,
      equipment,
      zipcode,
    },
  });
};

export default {
  goToSearchPage,
};
